import { Elements, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Footer from 'components/footers/FiveColumnWithBackground.js';
import Header from 'components/headers/light.js';
import PaymentPlans from 'components/pricing/PaymentPlans.js';
import { planDurations, plans } from 'config/plans';
// import AnimationRevealPage from 'helpers/AnimationRevealPage.js';
import { ReactComponent as ArrowLeftIcon } from 'images/arrow-left-3-icon.svg';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { createPaymentIntent, createSubscription } from 'services/api';
import useAuthStore from 'stores/authStore';
import styled from 'styled-components';
import tw from 'twin.macro';

const DEFAULT_ERROR_MESSAGE =
  'O pagamento falhou. Por favor, tente novamente ou use um método de pagamento diferente.';
const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col lg:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const LeftColumn = tw.div`relative lg:w-6/12 flex-shrink-0`;
const RightColumn = tw.div`relative mt-12 lg:mt-0 flex-1 flex flex-col lg:w-6/12`;

const FormContainer = tw.div`w-full mt-8`;
const Form = tw.form`mx-auto max-w-xs`;
const Input = tw.input`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const SubmitButton = styled.button`
  ${tw`mt-5 tracking-wide font-semibold bg-primary-500 text-gray-100 w-full py-4 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;

const Heading = tw.h1`font-bold text-3xl md:text-3xl lg:text-4xl xl:text-5xl text-gray-900 leading-tight`;
const Paragraph = tw.p`my-5 lg:my-8 text-base xl:text-lg`;

const ChooseAnotherPlanButton = styled.button`
  ${tw`mt-8 font-semibold text-primary-500 hocus:text-primary-700 focus:outline-none flex items-center transition duration-300`}
`;

const PlanDetails = tw.div`mt-8 border-t pt-8`;
const PlanItem = tw.p`flex justify-between items-center`;
const PlanItemText = tw.span`font-semibold`;
const PlanItemValue = tw.span``;

const ErrorMessage = tw.p`text-red-500 text-sm mt-2`;
const SuccessMessage = tw.p`text-green-500 text-sm mt-2`;

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

function CheckoutForm({ email, name, setName, onSubmit, error, success, processing }) {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    await onSubmit(stripe, elements);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Input
        type="text"
        placeholder="Nome Completo"
        value={name}
        onChange={(e) => setName(e.target.value)}
        required
      />
      <Input type="email" placeholder="Email" value={email} readOnly />
      <PaymentElement
        options={{
          style: {
            base: {
              fontSize: '16px',
              color: '#424770',
              '::placeholder': {
                color: '#aab7c4',
              },
            },
            invalid: {
              color: '#9e2146',
            },
          },
        }}
      />
      {error && <ErrorMessage>{error}</ErrorMessage>}
      {success && <SuccessMessage>Pagamento realizado com sucesso!</SuccessMessage>}
      <SubmitButton type="submit" disabled={!stripe || processing}>
        {processing ? 'Processando...' : 'Finalizar Pagamento'}
      </SubmitButton>
    </Form>
  );
}

export default function PaymentPage() {
  const [searchParams] = useSearchParams();
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [plan, setPlan] = useState('');
  const [duration, setDuration] = useState('');
  const [clientSecret, setClientSecret] = useState('');
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [processing, setProcessing] = useState(false);
  const { user, loading, userData, getRedirectUrl } = useAuthStore();
  const navigate = useNavigate();
  const [showPaymentPlans, setShowPaymentPlans] = useState(true);
  const [selectedPlanId, setSelectedPlanId] = useState(null);

  const handleCreatePaymentIntent = useCallback(async (planId, durationId) => {
    try {
      const data = await createPaymentIntent(planId, durationId);
      setClientSecret(data.clientSecret);
    } catch (error) {
      console.error('Error creating payment intent:', error);
    }
  }, []);

  const handleRedirect = useCallback(async () => {
    const redirectUrl = await getRedirectUrl();
    window.location.href = redirectUrl;
  }, [getRedirectUrl]);

  useEffect(() => {
    if (!loading && !user) {
      navigate('/assine');
    } else if (user) {
      console.log(userData);
      if (userData && userData.isActive && userData.role !== 'admin') {
        handleRedirect();
      } else {
        setEmail(user.email);
        setName(user.displayName);
      }
    }
  }, [user, loading, navigate, userData, getRedirectUrl, handleRedirect]);

  useEffect(() => {
    if (user) {
      const selectedPlan = searchParams.get('plan');
      const selectedDuration = searchParams.get('duration');
      const errorParam = searchParams.get('error');

      const storedPlan = localStorage.getItem('selectedPlan');
      const storedDuration = localStorage.getItem('selectedDuration');

      if (selectedPlan && selectedDuration) {
        setPlan(selectedPlan);
        setDuration(selectedDuration);
        setSelectedPlanId(selectedPlan);
        setShowPaymentPlans(false);
        handleCreatePaymentIntent(selectedPlan, selectedDuration);
      } else if (storedPlan && storedDuration) {
        setPlan(storedPlan);
        setDuration(storedDuration);
        setSelectedPlanId(storedPlan);
        setShowPaymentPlans(false);
        handleCreatePaymentIntent(storedPlan, storedDuration);
      }

      if (errorParam === 'requires_new_payment') {
        setError('É necessário um novo método de pagamento. Por favor, tente outro cartão.');
      }
    }
  }, [searchParams, user, handleCreatePaymentIntent, handleRedirect]);

  const handleChoosePlan = (planId, durationId) => {
    setPlan(planId);
    setDuration(durationId);
    setSelectedPlanId(planId);
    handleCreatePaymentIntent(planId, durationId);
    setShowPaymentPlans(false);
    localStorage.setItem('selectedPlan', planId);
    localStorage.setItem('selectedDuration', durationId);
  };

  const handleChooseAnotherPlan = () => {
    setShowPaymentPlans(true);
    setClientSecret('');
    setError(null);
    setSuccess(false);
    // setSelectedPlanId(null);
    localStorage.removeItem('selectedPlan');
    localStorage.removeItem('selectedDuration');
  };

  const handleSubmit = async (stripe, elements) => {
    if (!stripe || !elements) {
      console.log('stripe or elements not found');
      return;
    }

    setProcessing(true);
    setError(null);
    setSuccess(false);

    try {
      const { error: stripeError, paymentIntent } = await stripe.confirmPayment({
        elements,
        redirect: 'if_required',
        confirmParams: {
          return_url: `${window.location.origin}/payment-confirmation`,
          payment_method_data: {
            billing_details: {
              name,
              email,
            },
          },
        },
      });

      if (stripeError) {
        console.log('error confirming payment:', stripeError);
        setError(stripeError.message);
      } else if (paymentIntent && paymentIntent.status === 'succeeded') {
        try {
          const result = await createSubscription(plan, duration, user.uid, paymentIntent.id);
          console.log('Subscription result:', result);

          if (result && result.subscription && result.subscription.status === 'active') {
            setSuccess(true);
            localStorage.removeItem('selectedPlan');
            localStorage.removeItem('selectedDuration');
            setSuccessMessage('Pagamento realizado com sucesso! Bem-vindo ao Licitou!');
          } else {
            setError(DEFAULT_ERROR_MESSAGE);
          }
        } catch (error) {
          console.error('Error creating subscription:', error);
          setError(DEFAULT_ERROR_MESSAGE);
        }
      } else if (paymentIntent && paymentIntent.status === 'requires_payment_method') {
        setError(DEFAULT_ERROR_MESSAGE);
      }
    } catch (catchError) {
      console.log('error confirming payment:', catchError);
      setError(catchError.message);
    } finally {
      setProcessing(false);
    }
  };

  // Add a new state for success message
  const [successMessage, setSuccessMessage] = useState('');

  const selectedPlanDetails = plans.find((p) => p.id === plan);
  const selectedDurationDetails = planDurations.find((d) => d.id === duration);

  return (
    <>
      <Header />
      <Container>
        {showPaymentPlans ? (
          <PaymentPlans
            plans={plans}
            planDurations={planDurations}
            primaryButtonText="Escolher Plano"
            onChoose={handleChoosePlan}
            selectedPlanId={selectedPlanId}
          />
        ) : (
          <TwoColumn>
            <LeftColumn>
              <Heading>
                Finalize sua <span tw="text-primary-500">Assinatura</span>
              </Heading>
              <Paragraph>
                Você está prestes a desbloquear acesso a todas as licitações relevantes para o seu
                negócio. Confirme os detalhes abaixo e complete seu pagamento.
              </Paragraph>
              {selectedPlanDetails && selectedDurationDetails && (
                <PlanDetails>
                  <Heading as="h3" tw="text-2xl mb-4">
                    Resumo do Plano
                  </Heading>
                  <PlanItem>
                    <PlanItemText>Plano:</PlanItemText>
                    <PlanItemValue>{selectedPlanDetails.name}</PlanItemValue>
                  </PlanItem>
                  <PlanItem>
                    <PlanItemText>Duração:</PlanItemText>
                    <PlanItemValue>{selectedDurationDetails.text}</PlanItemValue>
                  </PlanItem>
                  <PlanItem>
                    <PlanItemText>Valor:</PlanItemText>
                    <PlanItemValue>
                      {
                        selectedPlanDetails.price[
                          planDurations.findIndex((d) => d.id === selectedDurationDetails.id)
                        ]
                      }
                    </PlanItemValue>
                  </PlanItem>
                </PlanDetails>
              )}
              <ChooseAnotherPlanButton onClick={handleChooseAnotherPlan}>
                <ArrowLeftIcon tw="w-4 h-4 mr-2" />
                Escolher outro plano
              </ChooseAnotherPlanButton>
            </LeftColumn>
            <RightColumn>
              <FormContainer>
                {clientSecret && !successMessage && (
                  <Elements
                    stripe={stripePromise}
                    options={{
                      clientSecret,
                      appearance: { theme: 'stripe' },
                    }}
                  >
                    <CheckoutForm
                      email={email}
                      name={name}
                      setName={setName}
                      onSubmit={handleSubmit}
                      error={error}
                      success={success}
                      processing={processing}
                    />
                  </Elements>
                )}
                {successMessage && (
                  <div tw="text-center">
                    <SuccessMessage>{successMessage}</SuccessMessage>
                    <SubmitButton onClick={handleRedirect}>Ir para o aplicativo</SubmitButton>
                  </div>
                )}
              </FormContainer>
            </RightColumn>
          </TwoColumn>
        )}
      </Container>
      <Footer />
    </>
  );
}
