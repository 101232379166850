import FAQ from 'components/faqs/SingleCol.js';
import Features from 'components/features/ThreeColWithSideImage.js';
import Footer from 'components/footers/FiveColumnWithBackground.js';
import Contact from 'components/forms/SimpleContactUs.js';
import Hero from 'components/hero/LandingHero.js';
import ComparePaymentPlans from 'components/pricing/ComparePaymentPlans.js';
import Testimonial from 'components/testimonials/Testimonials.js';
import { plans } from 'config/plans';
import { smoothScroll } from 'helpers/smoothScroll';
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useAuthStore from 'stores/authStore';
import tw from 'twin.macro';

export default function Landing() {
  const { user } = useAuthStore();
  const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
  const HighlightedText = tw.span`text-primary-500`;
  const navigate = useNavigate();
  const location = useLocation();
  const handleAnchorClick = (e) => {
    const target = e.target.getAttribute('href');
    if (target && target.startsWith('#')) {
      smoothScroll(e, target);
    }
  };

  useEffect(() => {
    console.log('Location changed', location.hash);
    if (location.hash) {
      setTimeout(() => {
        // console.log('Attempting to scroll to', location.hash);
        smoothScroll(null, location.hash);
      }, 300);
    }
  }, [location]);

  const handleChoosePlan = (planId, durationId) => {
    if (user) {
      navigate(`/pagar?plan=${planId}&duration=${durationId}`);
    } else {
      navigate('/assine');
    }
  };

  return (
    <>
      <Hero
        heading="Encontre as Melhores Licitações para sua Empresa."
        description="Nosso sistema busca diariamente novas licitações em todo o Brasil. Utilizamos inteligência artificial para recomendar as melhores oportunidades para o seu negócio."
        primaryButtonText="Comece Agora"
        primaryButtonUrl="#pricing"
        onAnchorClick={handleAnchorClick}
      />
      <ComparePaymentPlans id="pricing" plans={plans} onChoose={handleChoosePlan} />
      <Features
        id="about"
        subheading={<Subheading>Recursos</Subheading>}
        heading={
          <>
            Temos um <HighlightedText>Serviço Incrível.</HighlightedText>
          </>
        }
      />
      <Testimonial
        id="testimonials"
        subheading={<Subheading>Depoimentos</Subheading>}
        heading={
          <>
            Nossos Clientes <HighlightedText>Nos Adoram.</HighlightedText>
          </>
        }
        testimonials={[
          {
            stars: 5,
            profileImageSrc:
              'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3.25&w=512&h=512&q=80',
            heading: 'Experiência Incrível',
            quote:
              'O sistema de busca de licitações é extremamente eficiente e nos ajudou a aumentar significativamente nossa participação em processos licitatórios. As recomendações da IA são precisas e relevantes para o nosso negócio.',
            customerName: 'Maria Silva',
            customerTitle: 'Diretora, Construtora ABC',
          },
          {
            stars: 5,
            profileImageSrc:
              'https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=512&h=512&q=80',
            heading: 'Excelente Suporte e Atualizações em Tempo Real!',
            quote:
              'O suporte via WhatsApp é ágil e eficiente. As atualizações em tempo real nos mantêm sempre informados sobre as últimas oportunidades. Isso nos deu uma vantagem competitiva significativa no mercado de licitações.',
            customerName: 'João Oliveira',
            customerTitle: 'Gerente de Vendas, Tech Solutions Ltda.',
          },
        ]}
      />
      <FAQ
        id="faq"
        subheading={<Subheading>FAQ</Subheading>}
        heading={
          <>
            Perguntas <HighlightedText>Frequentes</HighlightedText>
          </>
        }
        faqs={[
          {
            question: 'Quantas licitações novas são adicionadas diariamente?',
            answer:
              'Nosso sistema busca entre 3.000 a 5.000 novas licitações por dia em todo o Brasil, garantindo que você tenha acesso às oportunidades mais recentes.',
          },
          {
            question: 'Como funciona a recomendação por inteligência artificial?',
            answer:
              'Nossa IA analisa o perfil da sua empresa e o histórico de licitações para recomendar as oportunidades mais relevantes e promissoras para o seu negócio.',
          },
          {
            question: 'O sistema é atualizado em tempo real?',
            answer:
              'Sim, nosso sistema é atualizado em tempo real, garantindo que você tenha acesso às informações mais recentes sobre licitações em todo o país.',
          },
          {
            question: 'Como funciona o suporte via WhatsApp?',
            answer:
              'Oferecemos suporte rápido e eficiente via WhatsApp durante o horário comercial. Nossa equipe está pronta para responder suas dúvidas e ajudar com qualquer problema.',
          },
          {
            question: 'Vocês oferecem avisos por e-mail?',
            answer:
              'Sim, enviamos avisos personalizados por e-mail sobre novas licitações que correspondem ao perfil da sua empresa, garantindo que você não perca nenhuma oportunidade.',
          },
          {
            question: 'O sistema é fácil de usar?',
            answer:
              'Sim, nosso sistema foi projetado para ser intuitivo e fácil de usar. Oferecemos também treinamento e suporte para garantir que você aproveite ao máximo todas as funcionalidades.',
          },
        ]}
      />
      <Contact
        id="contact"
        subheading={<Subheading>Contato</Subheading>}
        heading={
          <>
            Entre em <HighlightedText>Contato</HighlightedText>
          </>
        }
      />
      <Footer />
    </>
  );
}
