import { PrimaryButton as PrimaryButtonBase } from 'components/misc/Buttons.js';
import { SectionHeading, Subheading as SubheadingBase } from 'components/misc/Headings.js';
import { Container, ContentWithPaddingXl } from 'components/misc/Layouts.js';
import { SectionDescription } from 'components/misc/Typography.js';
import { planDurations as defaultPlanDurations, plans as defaultPlansConfig } from 'config/plans';
import { ReactComponent as CheckboxIcon } from 'feather-icons/dist/icons/check.svg';
import { ReactComponent as CloseIcon } from 'feather-icons/dist/icons/x.svg';
import React, { useState } from 'react';
import styled from 'styled-components';
import { css } from 'styled-components/macro';
import tw from 'twin.macro';

const HeaderContainer = tw.div`w-full flex flex-col items-center`;
const Subheading = tw(SubheadingBase)`mb-4`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center`;

const PlanDurationSwitcher = tw.div`block w-full max-w-md sm:inline-block sm:w-auto border-2 rounded-full px-1 py-1 mt-8`;
const SwitchButton = styled.button`
  ${tw`w-1/3 sm:w-32 px-4 sm:px-8 py-3 rounded-full focus:outline-none text-sm font-bold text-gray-700 transition duration-300`}
  ${(props) => props.active && tw`bg-primary-500 text-gray-100`}
`;

const PlansContainer = tw.div`flex flex-col items-center lg:flex-row lg:items-stretch lg:justify-between lg:mt-8 max-w-screen-lg mx-auto`;

const Plan = styled.div`
  ${tw`w-full max-w-sm mt-16 lg:mr-8 lg:last:mr-0 text-center px-8 rounded-lg shadow relative pt-2 text-gray-900 bg-white flex flex-col`}
  .planHighlight {
    ${tw`rounded-t-lg absolute top-0 inset-x-0 h-2`}
  }

  ${(props) =>
    props.featured &&
    css`
      background: rgb(100,21,255);
      background: linear-gradient(135deg, rgba(100,21,255,1) 0%, rgba(128,64,252,1) 100%);
      ${tw`bg-primary-500 text-gray-100`}
      .planHighlight {
        ${tw`hidden`}
      }
      .duration {
        ${tw`text-gray-200!`}
      }
      ${PlanFeatures} {
        ${tw`border-indigo-500`}
      }
      .feature:not(.mainFeature) {
        ${tw`text-gray-300!`}
      }
      ${BuyNowButton} {
        ${tw`bg-gray-100 text-primary-500 hocus:bg-gray-300 hocus:text-primary-800`}
    `}
`;

const PlanHeader = styled.div`
  ${tw`flex flex-col uppercase leading-relaxed py-8`}
  .name {
    ${tw`font-bold text-xl`}
  }
  .price {
    ${tw`font-bold text-4xl sm:text-5xl my-1`}
  }
  .duration {
    ${tw`text-gray-500 font-bold tracking-widest`}
  }
`;

const PlanFeatures = styled.div`
  ${tw`flex flex-col -mx-8 px-8 py-8 border-t-2 border-b-2 flex-1`}
  .feature {
    ${tw`mt-5 first:mt-0 font-medium`}
  }
  .mainFeature {
    ${tw`text-xl font-bold tracking-wide`}
  }
`;

const PlanAction = tw.div`px-4 pb-8`;
const BuyNowButton = styled(PrimaryButtonBase)`
  ${tw`rounded-full tracking-wider py-4 w-full text-sm hover:shadow-xl transform hocus:translate-x-px hocus:-translate-y-px focus:shadow-outline`}
  ${(props) => props.disabled && tw`opacity-50 cursor-not-allowed`}
`;

const FeatureIcon = styled.span`
  ${tw`inline-block mr-2`}
  svg {
    ${tw`w-4 h-4`}
  }
`;

export default function ComparePaymentPlans({
  id = 'pricing',
  subheading = 'Compare os planos',
  heading = 'Escolha o plano ideal para você',
  description = 'Temos planos para todos os tamanhos de empresa. Escolha o que melhor se adapta às suas necessidades.',
  plans = null,
  primaryButtonText = 'Assine Agora',
  planDurations = defaultPlanDurations,
  onChoose = (planId, durationId) => {
    console.log(planId, durationId);
  },
  inDevelopmentText = 'Em breve',
}) {
  const defaultPlans = defaultPlansConfig;

  if (!plans) plans = defaultPlans;

  const [activeDurationIndex, setActiveDurationIndex] = useState(0);

  return (
    <Container id={id}>
      <ContentWithPaddingXl>
        <HeaderContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          <Heading>{heading}</Heading>
          {description && <Description>{description}</Description>}
          <PlanDurationSwitcher>
            {planDurations.map((planDuration, index) => (
              <SwitchButton
                active={activeDurationIndex === index}
                key={index}
                onClick={() => setActiveDurationIndex(index)}
              >
                {planDuration.switcherText}
              </SwitchButton>
            ))}
          </PlanDurationSwitcher>
        </HeaderContainer>
        <PlansContainer>
          {plans.map((plan, index) => (
            <Plan key={index} featured={plan.featured}>
              {!plan.featured && <div className="planHighlight" />}
              <PlanHeader>
                <span className="name">{plan.name}</span>
                <span className="price">{plan.price[activeDurationIndex]}</span>
                <span className="duration">{planDurations[activeDurationIndex].text}</span>
              </PlanHeader>
              <PlanFeatures>
                <span className="feature mainFeature">{plan.mainFeature}</span>
                {plan.features.map((feature, index) => (
                  <span key={index} className="feature">
                    <FeatureIcon>{feature ? <CheckboxIcon /> : <CloseIcon />}</FeatureIcon>
                    {typeof feature === 'string' ? feature : ''}
                  </span>
                ))}
              </PlanFeatures>
              <PlanAction>
                <BuyNowButton
                  onClick={() =>
                    !plan.inDevelopment && onChoose(plan.id, planDurations[activeDurationIndex].id)
                  }
                  disabled={plan.inDevelopment}
                >
                  {plan.inDevelopment ? inDevelopmentText : primaryButtonText}
                </BuyNowButton>
              </PlanAction>
            </Plan>
          ))}
        </PlansContainer>
      </ContentWithPaddingXl>
    </Container>
  );
}
