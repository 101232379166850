import { PrimaryButton as PrimaryButtonBase } from 'components/misc/Buttons.js';
import { SectionHeading, Subheading as SubheadingBase } from 'components/misc/Headings.js';
import { Container, ContentWithPaddingXl } from 'components/misc/Layouts.js';
import { SectionDescription } from 'components/misc/Typography.js';
import { planDurations as defaultPlanDurations, plans as defaultPlansConfig } from 'config/plans';
import { ReactComponent as SvgDecoratorBlob1 } from 'images/svg-decorator-blob-6.svg';
import { ReactComponent as SvgDecoratorBlob2 } from 'images/svg-decorator-blob-7.svg';
import React, { useState } from 'react';
import styled from 'styled-components';
import { css } from 'styled-components/macro'; //eslint-disable-line
import tw from 'twin.macro';
import ComparePaymentPlansModal from './ComparePaymentPlansModal.js';

const HeaderContainer = tw.div`w-full flex flex-col items-center`;
const Subheading = tw(SubheadingBase)`mb-4`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center`;

const PlanDurationSwitcher = tw.div`block w-full max-w-md sm:inline-block sm:w-auto border-2 rounded-full px-1 py-1 mt-8`;
const SwitchButton = styled.button`
  ${tw`w-1/3 sm:w-32 px-4 sm:px-8 py-3 rounded-full focus:outline-none text-sm font-bold text-gray-700 transition duration-300`}
  ${(props) => props.active && tw`bg-primary-500 text-gray-100`}
`;

const PlansContainer = tw.div`flex flex-col lg:flex-row items-center lg:items-stretch lg:justify-between mt-16 lg:mt-24 max-w-screen-lg mx-auto`;

const Plan = styled.div`
  ${tw`w-full max-w-sm mt-16 lg:mr-8 lg:last:mr-0 text-center px-8 rounded-lg shadow relative pt-2 text-gray-900 bg-white flex flex-col`}
  .planHighlight {
    ${tw`rounded-t-lg absolute top-0 inset-x-0 h-2`}
  }

  ${(props) =>
    props.featured &&
    css`
      background: rgb(100, 21, 255);
      background: linear-gradient(135deg, rgba(100, 21, 255, 1) 0%, rgba(128, 64, 252, 1) 100%);
      ${tw`bg-primary-500 text-gray-100`}
    `}
`;

const PlanHeader = styled.div`
  ${tw`flex flex-col leading-relaxed py-8`}
  .name {
    ${tw`font-bold text-xl`}
  }
  .price {
    ${tw`font-bold text-4xl sm:text-5xl my-1`}
  }
  .duration {
    ${tw`text-gray-500 font-medium tracking-widest`}
  }
`;

const PlanFeatures = styled.div`
  ${tw`flex-1 px-8 py-8`}
  .feature {
    ${tw`mt-5 first:mt-0 font-medium`}
  }
`;

const PlanAction = tw.div`px-4 pb-8`;
const BuyNowButton = styled(PrimaryButtonBase)`
  ${tw`rounded-full tracking-wider py-4 w-full text-sm hover:shadow-xl transform hocus:translate-x-px hocus:-translate-y-px focus:shadow-outline`}
  ${(props) => props.disabled && tw`opacity-50 cursor-not-allowed`}
`;

const CompareButton = styled.button`
  ${tw`mt-4 w-full py-3 text-sm font-medium rounded-full transition-all duration-300`}
  ${(props) =>
    props.featured
      ? tw`text-white bg-white bg-opacity-15 border border-white hover:bg-opacity-25`
      : tw`text-primary-500 bg-transparent border border-primary-500 hover:bg-primary-500 hover:bg-opacity-10`}
`;
const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-64 w-64 opacity-25 transform -translate-x-2/3 -translate-y-1/2`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute right-0 top-0 h-64 w-64 opacity-25 transform translate-x-2/3 translate-y-1/2 fill-current text-teal-300`}
`;

export default function PaymentPlans({
  subheading = 'Preços',
  heading = 'Planos Flexíveis',
  description = 'Escolha o plano que melhor se adapta às necessidades da sua empresa.',
  plans = defaultPlansConfig,
  primaryButtonText = 'Assine Agora',
  planDurations = defaultPlanDurations,
  onChoose = () => console.log('Choose clicked'),
  selectedPlanId = null, // Add this line
}) {
  const [activeDurationIndex, setActiveDurationIndex] = useState(0);
  const [isComparisonModalOpen, setIsComparisonModalOpen] = useState(false);

  return (
    <Container>
      <ContentWithPaddingXl>
        <HeaderContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          <Heading>{heading}</Heading>
          {description && <Description>{description}</Description>}
          {planDurations && (
            <PlanDurationSwitcher>
              {planDurations.map((planDuration, index) => (
                <SwitchButton
                  active={activeDurationIndex === index}
                  key={index}
                  onClick={() => setActiveDurationIndex(index)}
                >
                  {planDuration.switcherText}
                </SwitchButton>
              ))}
            </PlanDurationSwitcher>
          )}
        </HeaderContainer>
        <PlansContainer>
          {plans.map((plan, index) => (
            <Plan
              key={index}
              featured={selectedPlanId ? plan.id === selectedPlanId : plan.featured}
            >
              {!plan.featured && <div className="planHighlight" />}
              <PlanHeader>
                <span className="name">{plan.name}</span>
                <span className="price">{plan.price[activeDurationIndex]}</span>
                <span className="duration">{planDurations[activeDurationIndex].text}</span>
              </PlanHeader>
              <PlanFeatures>
                <span className="feature">{plan.mainFeature}</span>
              </PlanFeatures>
              <PlanAction>
                <BuyNowButton
                  onClick={() => onChoose(plan.id, planDurations[activeDurationIndex].id)}
                  disabled={plan.inDevelopment}
                >
                  {plan.inDevelopment ? 'Em breve' : primaryButtonText}
                </BuyNowButton>
                <CompareButton
                  onClick={() => setIsComparisonModalOpen(true)}
                  featured={selectedPlanId ? plan.id === selectedPlanId : plan.featured}
                >
                  Compare
                </CompareButton>
              </PlanAction>
            </Plan>
          ))}
        </PlansContainer>
      </ContentWithPaddingXl>
      <DecoratorBlob1 />
      <DecoratorBlob2 />
      {isComparisonModalOpen && (
        <ComparePaymentPlansModal
          isOpen={isComparisonModalOpen}
          onClose={() => setIsComparisonModalOpen(false)}
          plans={plans}
          planDurations={planDurations}
          onChoose={onChoose}
        />
      )}
    </Container>
  );
}
