import Footer from 'components/footers/FiveColumnWithBackground.js';
import Header from 'components/headers/light.js';
// import AnimationRevealPage from 'helpers/AnimationRevealPage.js';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import tw from 'twin.macro';

const Container = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;
const Content = tw.div`mt-16 text-center`;
const Heading = tw.h1`text-3xl font-bold text-gray-900`;
const Text = tw.p`mt-4 text-xl text-gray-600`;
const Button = styled(Link)`
  ${tw`mt-8 inline-block px-8 py-3 bg-primary-500 text-white font-bold rounded-full hocus:bg-primary-700 hocus:text-white focus:shadow-outline focus:outline-none transition duration-300`}
`;

export default function NotFoundPage() {
  return (
    <>
      <Header />
      <Container>
        <Content>
          <Heading>404 - Página não encontrada</Heading>
          <Text>Desculpe, a página que você está procurando não existe.</Text>
          <Button to="/">Voltar para a página inicial</Button>
        </Content>
      </Container>
      <Footer />
    </>
  );
}
