import { PrimaryButton as PrimaryButtonBase } from 'components/misc/Buttons.js';
import { SectionHeading } from 'components/misc/Headings.js';
import {
  Container as ContainerBase,
  Content2Xl,
  ContentWithVerticalPadding,
} from 'components/misc/Layouts.js';
import { SectionDescription } from 'components/misc/Typography.js';
import logoImageSrc from 'images/logo-light.svg';
import serverIllustrationImageSrc from 'images/server-illustration-2.svg';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import tw from 'twin.macro';
import useAuthStore from '../../stores/authStore';
import HeaderBase, {
  LogoLink as LogoLinkBase,
  NavLink as NavLinkBase,
  NavLinks,
  PrimaryLink as PrimaryLinkBase,
} from '../headers/light.js';

const PrimaryBackgroundContainer = tw.div`-mx-8 px-8 bg-primary-900 text-gray-100`;
const Header = tw(HeaderBase)`max-w-none -mt-8 py-8 -mx-8 px-8`;
const NavLink = tw(NavLinkBase)`lg:text-gray-100 lg:hocus:text-gray-300 lg:hocus:border-gray-100`;
const LogoLink = tw(LogoLinkBase)`text-gray-100 hocus:text-gray-300`;
// const NavText = tw(NavTextBase)`text-white hocus:text-gray-300`;
const PrimaryLink = tw(PrimaryLinkBase)`shadow-raised lg:bg-primary-400 lg:hocus:bg-primary-500`;

const Container = tw(ContainerBase)``;
const Row = tw.div`flex items-center flex-col lg:flex-row`;
const Column = tw.div`lg:w-1/2`;
const TextColumn = tw.div`text-center lg:text-left`;
const IllustrationColumn = tw(Column)`mt-16 lg:mt-0 lg:ml-16`;
const Heading = tw(SectionHeading)`max-w-3xl lg:max-w-4xl lg:text-left leading-tight`;
const Description = tw(
  SectionDescription,
)`mt-4 max-w-2xl text-gray-100 lg:text-base mx-auto lg:mx-0`;
const PrimaryButton = tw(
  PrimaryButtonBase,
)`mt-8 text-sm sm:text-base px-6 py-5 sm:px-10 sm:py-5 bg-primary-400 inline-block hocus:bg-primary-500`;
const Image = tw.img`w-144 ml-auto`;

export default function TwoColumnWithPrimaryBackground({
  heading = 'Encontre as Melhores Licitações para sua Empresa.',
  description = 'Nosso sistema busca diariamente novas licitações em todo o Brasil. Utilizamos inteligência artificial para recomendar as melhores oportunidades para o seu negócio.',
  primaryButtonText = 'Comece Agora',
  primaryButtonUrl = '#',
  imageSrc = serverIllustrationImageSrc,
  onAnchorClick = () => {},
}) {
  const { user, signOut, getRedirectUrl } = useAuthStore();
  const [redirectUrl, setRedirectUrl] = React.useState(null);

  React.useEffect(() => {
    getRedirectUrl().then((url) => {
      setRedirectUrl(url);
    });
  }, [getRedirectUrl, user]);

  const navigate = useNavigate();
  const handleSignOut = () => {
    signOut();
    navigate('/');
  };

  const logoLink = (
    <LogoLink href="/">
      <img src={logoImageSrc} alt="Logo" />
      Licitou
    </LogoLink>
  );

  const navLinks = [
    <NavLinks key={1}>
      <NavLink href="#about" onClick={onAnchorClick}>
        Sobre
      </NavLink>
      <NavLink href="#pricing" onClick={onAnchorClick}>
        Preços
      </NavLink>
      <NavLink href="#faq" onClick={onAnchorClick}>
        FAQ
      </NavLink>
      <NavLink href="#contact" onClick={onAnchorClick}>
        Contato
      </NavLink>

      <PrimaryLink href={redirectUrl || 'https://app.licitou.com.br'}>
        Ir para Licitações
      </PrimaryLink>
      {user ? (
        <>
          {/* <NavText>Bem-vindo, {user.displayName}</NavText> */}
          <NavLink onClick={handleSignOut}>Sair</NavLink>
        </>
      ) : (
        <>
          <NavLink href="/login" tw="lg:ml-12!">
            Entrar
          </NavLink>
          <PrimaryLink href="/assine">Cadastre-se</PrimaryLink>
        </>
      )}
    </NavLinks>,
  ];

  return (
    <PrimaryBackgroundContainer>
      <Content2Xl>
        <Header logoLink={logoLink} links={navLinks} onAnchorClick={onAnchorClick} />
        <Container>
          <ContentWithVerticalPadding>
            <Row>
              <TextColumn>
                <Heading>{heading}</Heading>
                <Description>{description}</Description>
                <PrimaryButton as="a" href={primaryButtonUrl}>
                  {primaryButtonText}
                </PrimaryButton>
              </TextColumn>
              <IllustrationColumn>
                <Image src={imageSrc} />
              </IllustrationColumn>
            </Row>
          </ContentWithVerticalPadding>
        </Container>
      </Content2Xl>
    </PrimaryBackgroundContainer>
  );
}
