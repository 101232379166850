import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import { SectionHeading } from "components/misc/Headings";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;

export default function TermsOfServicePage({ headingText = "Termos e Condições" }) {
  return (
    <AnimationRevealPage>
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>
            <p>Última atualização: 21 de abril de 2048</p>

            <p>Por favor, leia estes termos e condições cuidadosamente antes de usar nosso Serviço.</p>

            <h1>Interpretação e Definições</h1>
            <h2>Interpretação</h2>
            <p>
              As palavras cujas letras iniciais são maiúsculas têm significados definidos nas seguintes condições.
            </p>
            <p>
              As seguintes definições terão o mesmo significado, independentemente de aparecerem no singular ou no plural.
            </p>

            <h2>Definições</h2>
            <p>Para os fins destes Termos e Condições:</p>
            <ul>
              <li>
                <strong>Afiliada</strong> significa uma entidade que controla, é controlada por ou está sob controle comum com uma parte, onde &quot;controle&quot; significa propriedade de 50% ou mais das ações, participação acionária ou outros títulos com direito a voto para eleição de diretores ou outra autoridade de gestão.
              </li>
              <li>
                <strong>Empresa</strong> (referida como &quot;a Empresa&quot;, &quot;Nós&quot;, &quot;Nos&quot; ou &quot;Nosso&quot; neste Acordo) refere-se a Licitou.
              </li>
              <li>
                <strong>País</strong> refere-se a: Brasil
              </li>
              <li>
                <strong>Serviço</strong> refere-se ao Website.
              </li>
              <li>
                <strong>Termos e Condições</strong> (também referidos como &quot;Termos&quot;) significam estes Termos e Condições que constituem o acordo completo entre Você e a Empresa em relação ao uso do Serviço.
              </li>
              <li>
                <strong>Serviço de Mídia Social de Terceiros</strong> refere-se a qualquer serviço ou conteúdo (incluindo dados, informações, produtos ou serviços) fornecido por um terceiro que pode ser exibido, incluído ou disponibilizado pelo Serviço.
              </li>
              <li>
                <strong>Website</strong> refere-se a Licitou, acessível a partir de https://licitou.com.br
              </li>
              <li>
                <strong>Você</strong> significa o indivíduo que acessa ou usa o Serviço, ou a empresa, ou outra entidade legal em nome da qual tal indivíduo está acessando ou usando o Serviço, conforme aplicável.
              </li>
            </ul>

            <h1>Reconhecimento</h1>
            <p>
              Estes são os Termos e Condições que regem o uso deste Serviço e o acordo que opera entre Você e a Empresa. Estes Termos e Condições estabelecem os direitos e obrigações de todos os usuários em relação ao uso do Serviço.
            </p>
            <p>
              Seu acesso e uso do Serviço está condicionado à sua aceitação e conformidade com estes Termos e Condições. Estes Termos e Condições se aplicam a todos os visitantes, usuários e outros que acessam ou usam o Serviço.
            </p>
            <p>
              Ao acessar ou usar o Serviço, você concorda em ficar vinculado a estes Termos e Condições. Se você discordar de qualquer parte destes Termos e Condições, então você não poderá acessar o Serviço.
            </p>
            <p>
              Você declara que tem mais de 18 anos. A Empresa não permite que menores de 18 anos usem o Serviço.
            </p>
            <p>
              Seu acesso e uso do Serviço também está condicionado à sua aceitação e conformidade com a Política de Privacidade da Empresa. Nossa Política de Privacidade descreve nossas políticas e procedimentos sobre a coleta, uso e divulgação de suas informações pessoais quando você usa o Aplicativo ou o Website e informa sobre seus direitos de privacidade e como a lei protege você. Por favor, leia nossa Política de Privacidade cuidadosamente antes de usar nosso Serviço.
            </p>

            <h1>Links para Outros Websites</h1>
            <p>
              Nosso Serviço pode conter links para sites ou serviços de terceiros que não são de propriedade ou controlados pela Empresa.
            </p>
            <p>
              A Empresa não tem controle sobre, e não assume responsabilidade pelo conteúdo, políticas de privacidade ou práticas de quaisquer sites ou serviços de terceiros. Você reconhece e concorda ainda que a Empresa não será responsável, direta ou indiretamente, por qualquer dano ou perda causada ou alegadamente causada por ou em conexão com o uso ou dependência de qualquer conteúdo, bens ou serviços disponíveis em ou através de tais sites ou serviços.
            </p>
            <p>
              Aconselhamos fortemente que você leia os termos e condições e as políticas de privacidade de quaisquer sites ou serviços de terceiros que você visitar.
            </p>

            <h1>Rescisão</h1>
            <p>
              Podemos rescindir ou suspender seu acesso imediatamente, sem aviso prévio ou responsabilidade, por qualquer motivo, incluindo, sem limitação, se você violar estes Termos e Condições.
            </p>
            <p>Após a rescisão, seu direito de usar o Serviço cessará imediatamente.</p>

            <h1>Limitação de Responsabilidade</h1>
            <p>
              Não obstante quaisquer danos que você possa incorrer, a responsabilidade total da Empresa e de qualquer um de seus fornecedores sob qualquer disposição destes Termos e seu recurso exclusivo para todos os anteriores será limitada ao valor realmente pago por você através do Serviço ou 100 USD se você não tiver comprado nada através do Serviço.
            </p>
            <p>
              Na máxima extensão permitida pela lei aplicável, em nenhum caso a Empresa ou seus fornecedores serão responsáveis por quaisquer danos especiais, incidentais, indiretos ou consequenciais (incluindo, mas não se limitando a, danos por perda de lucros, perda de dados ou outras informações, por interrupção de negócios, por danos pessoais, perda de privacidade decorrente de ou de qualquer forma relacionada ao uso ou incapacidade de usar o Serviço, software de terceiros e/ou hardware de terceiros usado com o Serviço, ou de outra forma em conexão com qualquer disposição destes Termos), mesmo que a Empresa ou qualquer fornecedor tenha sido avisado da possibilidade de tais danos e mesmo que o recurso falhe em seu propósito essencial.
            </p>
            <p>
              Alguns estados não permitem a exclusão de garantias implícitas ou a limitação de responsabilidade por danos incidentais ou consequenciais, o que significa que algumas das limitações acima podem não se aplicar. Nesses estados, a responsabilidade de cada parte será limitada à maior extensão permitida por lei.
            </p>

            <h1>Isenção de Garantias &quot;COMO ESTÁ&quot; e &quot;CONFORME DISPONÍVEL&quot;</h1>
            <p>
              O Serviço é fornecido a você &quot;COMO ESTÁ&quot; e &quot;CONFORME DISPONÍVEL&quot; e com todas as falhas e defeitos sem garantia de qualquer tipo. Na máxima extensão permitida pela lei aplicável, a Empresa, em seu próprio nome e em nome de suas Afiliadas e seus respectivos licenciadores e prestadores de serviços, renuncia expressamente a todas as garantias, sejam expressas, implícitas, estatutárias ou de outra forma, com relação ao Serviço, incluindo todas as garantias implícitas de comercialização, adequação a um determinado fim, título e não violação, e garantias que possam surgir do curso de negociação, curso de desempenho, uso ou prática comercial. Sem limitação ao precedente, a Empresa não oferece garantia ou compromisso, e não faz qualquer representação de qualquer tipo de que o Serviço atenderá aos seus requisitos, alcançará quaisquer resultados pretendidos, será compatível ou funcionará com qualquer outro software, aplicativos, sistemas ou serviços, operará sem interrupção, atenderá a quaisquer padrões de desempenho ou confiabilidade ou estará livre de erros ou que quaisquer erros ou defeitos podem ou serão corrigidos.
            </p>
            <p>
              Sem limitar o precedente, nem a Empresa nem qualquer um dos fornecedores da empresa faz qualquer representação ou garantia de qualquer tipo, expressa ou implícita: (i) quanto à operação ou disponibilidade do Serviço, ou as informações, conteúdo e materiais ou produtos incluídos nele; (ii) que o Serviço será ininterrupto ou livre de erros; (iii) quanto à precisão, confiabilidade ou atualidade de qualquer informação ou conteúdo fornecido através do Serviço; ou (iv) que o Serviço, seus servidores, o conteúdo ou e-mails enviados de ou em nome da Empresa estão livres de vírus, scripts, cavalos de Troia, worms, malware, bombas-relógio ou outros componentes prejudiciais.
            </p>
            <p>
              Algumas jurisdições não permitem a exclusão de certos tipos de garantias ou limitações sobre os direitos estatutários aplicáveis de um consumidor, portanto, algumas ou todas as exclusões e limitações acima podem não se aplicar a você. Mas, nesse caso, as exclusões e limitações estabelecidas nesta seção serão aplicadas na maior extensão possível de acordo com a lei aplicável.
            </p>

            <h1>Lei Aplicável</h1>
            <p>
              As leis do País, excluindo seus conflitos de regras legais, regerão estes Termos e o uso do Serviço. Seu uso do Aplicativo também pode estar sujeito a outras leis locais, estaduais, nacionais ou internacionais.
            </p>

            <h1>Resolução de Disputas</h1>
            <p>
              Se você tiver alguma preocupação ou disputa sobre o Serviço, você concorda em tentar resolver a disputa informalmente entrando em contato com a Empresa.
            </p>

            <h1>Para Usuários da União Europeia (UE)</h1>
            <p>
              Se você for um consumidor da União Europeia, você se beneficiará de quaisquer disposições obrigatórias da lei do país em que você é residente.
            </p>

            <h1>Conformidade Legal do Brasil</h1>
            <p>
              Você declara e garante que (i) você não está localizado em um país que está sujeito a embargo do governo do Brasil, ou que foi designado pelo governo do Brasil como um país &quot;apoiador do terrorismo&quot;, e (ii) você não está listado em nenhuma lista do governo do Brasil de partes proibidas ou restritas.
            </p>

            <h1>Separabilidade e Renúncia</h1>
            <h2>Separabilidade</h2>
            <p>
              Se qualquer disposição destes Termos for considerada inexequível ou inválida, tal disposição será alterada e interpretada para alcançar os objetivos de tal disposição na maior extensão possível sob a lei aplicável e as disposições restantes continuarão em pleno vigor e efeito.
            </p>

            <h2>Renúncia</h2>
            <p>
              Exceto conforme previsto neste documento, a falha em exercer um direito ou exigir o cumprimento de uma obrigação sob estes Termos não afetará a capacidade de uma parte de exercer tal direito ou exigir tal cumprimento a qualquer momento depois, nem a renúncia de uma violação constituirá uma renúncia de qualquer violação subsequente.
            </p>

            <h1>Interpretação da Tradução</h1>
            <p>
              Estes Termos e Condições podem ter sido traduzidos se os tivermos disponibilizado a você em nosso Serviço.
            </p>
            <p>Você concorda que o texto original em inglês prevalecerá em caso de disputa.</p>

            <h1>Alterações a estes Termos e Condições</h1>
            <p>
              Reservamo-nos o direito, a nosso exclusivo critério, de modificar ou substituir estes Termos a qualquer momento. Se uma revisão for material, faremos esforços razoáveis para fornecer pelo menos 30 dias de aviso antes de quaisquer novos termos entrarem em vigor. O que constitui uma mudança material será determinado a nosso exclusivo critério.
            </p>
            <p>
              Ao continuar a acessar ou usar nosso Serviço após essas revisões se tornarem eficazes, você concorda em ficar vinculado aos termos revisados. Se você não concordar com os novos termos, no todo ou em parte, por favor, pare de usar o website e o Serviço.
            </p>

            <h1>Contate-Nos</h1>
            <p>Se você tiver alguma dúvida sobre estes Termos e Condições, você pode nos contatar:</p>
            <ul>
              <li>Por e-mail: suporte@licitou.com.br</li>
              <li>Por telefone: +55 41 988229199</li>
            </ul>
          </Text>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
}