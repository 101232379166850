import Footer from 'components/footers/FiveColumnWithBackground.js';
import Header from 'components/headers/light.js';
import AnimationRevealPage from 'helpers/AnimationRevealPage.js';
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { createSubscription } from 'services/api';
import useAuthStore from 'stores/authStore';
import tw from 'twin.macro';

const Container = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;
const Content = tw.div`mt-16 text-center`;

export default function PaymentConfirmationPage() {
  const [searchParams] = useSearchParams();
  const [status, setStatus] = useState('processing');
  const [errorMessage, setErrorMessage] = useState('');
  const { user, loading, getRedirectUrl } = useAuthStore();
  const navigate = useNavigate();

  useEffect(() => {
    const handlePaymentConfirmation = async () => {
      const paymentIntent = searchParams.get('payment_intent');
      const redirectStatus = searchParams.get('redirect_status');
      if (paymentIntent && redirectStatus === 'succeeded') {
        try {
          const plan = localStorage.getItem('selectedPlan');
          const duration = localStorage.getItem('selectedDuration');

          const result = await createSubscription(plan, duration, user.uid, paymentIntent);

          if (result.subscription && result.subscription.status === 'requires_new_payment_method') {
            setStatus('requires_new_payment');
            setErrorMessage(result.subscription.message);
            setTimeout(
              () => navigate(`/pagar?error=requires_new_payment&plan=${plan}&duration=${duration}`),
              3000,
            );
          } else if (result.status === 'active' || result.status === 'trialing') {
            setStatus('success');
            localStorage.removeItem('selectedPlan');
            localStorage.removeItem('selectedDuration');
            const redirectUrl = await getRedirectUrl();
            setTimeout(() => (window.location.href = redirectUrl), 3000);
          } else {
            setStatus('error');
            setErrorMessage('Ocorreu um erro inesperado ao processar seu pagamento.');
          }
        } catch (error) {
          console.error('Error creating subscription:', error);
          setStatus('error');
          setErrorMessage('Ocorreu um erro ao processar seu pagamento.');
        }
      } else {
        console.error('Payment intent or redirect status not found');
        setStatus('error');
        setErrorMessage('Informações de pagamento não encontradas.');
      }
    };

    if (!loading) {
      if (user) {
        handlePaymentConfirmation();
      } else {
        console.error('User not found');
        setStatus('error');
      }
    }
  }, [user, loading, searchParams, navigate, getRedirectUrl]);

  if (loading) {
    return (
      <AnimationRevealPage>
        <Header />
        <Container>
          <Content>
            <h2>Carregando...</h2>
          </Content>
        </Container>
        <Footer />
      </AnimationRevealPage>
    );
  }

  return (
    <AnimationRevealPage>
      <Header />
      <Container>
        <Content>
          {status === 'processing' && <h2>Processando seu pagamento...</h2>}
          {status === 'success' && (
            <>
              <h2>Pagamento confirmado com sucesso!</h2>
              <p>Você será redirecionado em alguns segundos...</p>
            </>
          )}
          {status === 'error' && (
            <>
              <h2>Ocorreu um erro ao processar seu pagamento.</h2>
              <p>{errorMessage}</p>
              <p>Por favor, entre em contato com o suporte.</p>
            </>
          )}
          {status === 'requires_new_payment' && (
            <>
              <h2>É necessário um novo método de pagamento.</h2>
              <p>{errorMessage}</p>
              <p>Você será redirecionado para tentar novamente...</p>
            </>
          )}
        </Content>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
}
