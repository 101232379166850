import { DialogContent, DialogOverlay } from '@reach/dialog';
import '@reach/dialog/styles.css';
import { PrimaryButton as PrimaryButtonBase } from 'components/misc/Buttons.js';
import { ReactComponent as CheckboxIcon } from 'feather-icons/dist/icons/check.svg';
import { ReactComponent as CloseIcon } from 'feather-icons/dist/icons/x.svg';
import React, { useState } from 'react';
import styled from 'styled-components';
import { css } from 'styled-components/macro';
import tw from 'twin.macro';

const StyledDialogOverlay = styled(DialogOverlay)`
  ${tw`fixed inset-0 z-50 bg-black bg-opacity-50`}
`;

const StyledDialogContent = styled(DialogContent)`
  ${tw`w-full max-w-screen-xl mx-auto my-16 bg-white rounded-lg shadow-2xl p-8`}
`;

const CloseButton = tw.button`absolute top-0 right-0 mt-4 mr-4 text-gray-500 hover:text-gray-700`;

const HeaderContainer = tw.div`w-full flex flex-col items-center`;
const Heading = tw.h2`text-3xl sm:text-4xl font-bold`;
const Description = tw.p`mt-4 text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100 max-w-xl`;

const PlanDurationSwitcher = tw.div`block w-full max-w-md sm:inline-block sm:w-auto border-2 rounded-full px-1 py-1 mt-8`;
const SwitchButton = styled.button`
  ${tw`w-1/3 sm:w-32 px-4 sm:px-8 py-3 rounded-full focus:outline-none text-sm font-bold text-gray-700 transition duration-300`}
  ${(props) => props.active && tw`bg-primary-500 text-gray-100`}
`;

const PlansContainer = tw.div`mt-16 flex flex-col items-center lg:flex-row lg:items-stretch lg:justify-between`;

const Plan = styled.div`
  ${tw`w-full max-w-sm mt-16 lg:mr-8 lg:last:mr-0 text-center px-8 rounded-lg shadow relative pt-2 text-gray-900 bg-white flex flex-col`}
  .planHighlight {
    ${tw`rounded-t-lg absolute top-0 inset-x-0 h-2`}
  }
  ${(props) =>
    props.featured &&
    css`
      background: rgb(100, 21, 255);
      background: linear-gradient(135deg, rgba(100, 21, 255, 1) 0%, rgba(128, 64, 252, 1) 100%);
      ${tw`bg-primary-500 text-gray-100`}
    `}
`;

const PlanHeader = styled.div`
  ${tw`flex flex-col uppercase leading-relaxed py-8`}
  .name {
    ${tw`font-bold text-xl`}
  }
  .price {
    ${tw`font-bold text-4xl sm:text-5xl my-1`}
  }
  .duration {
    ${tw`text-gray-500 font-bold tracking-widest`}
  }
`;

const PlanFeatures = styled.div`
  ${tw`flex flex-col -mx-8 px-8 py-8 border-t-2 border-b-2 flex-1`}
  .feature {
    ${tw`mt-5 first:mt-0 font-medium`}
  }
`;

const PlanAction = tw.div`px-4 pb-8`;
const BuyNowButton = styled(PrimaryButtonBase)`
  ${tw`rounded-full tracking-wider py-4 w-full text-sm hover:shadow-xl transform hocus:translate-x-px hocus:-translate-y-px focus:shadow-outline`}
  ${(props) => props.disabled && tw`opacity-50 cursor-not-allowed`}
`;

const FeatureIcon = styled.span`
  ${tw`inline-block mr-2`}
  svg {
    ${tw`w-4 h-4`}
  }
`;

export default function ComparePaymentPlansModal({
  isOpen,
  onClose,
  plans,
  planDurations,
  onChoose,
}) {
  const [activeDurationIndex, setActiveDurationIndex] = useState(0);

  return (
    <StyledDialogOverlay isOpen={isOpen} onDismiss={onClose}>
      <StyledDialogContent aria-label="Plan Comparison">
        <CloseButton onClick={onClose}>×</CloseButton>
        <HeaderContainer>
          <Heading>Compare os planos</Heading>
          <Description>
            Escolha o plano que melhor se adapta às necessidades da sua empresa.
          </Description>
          <PlanDurationSwitcher>
            {planDurations.map((planDuration, index) => (
              <SwitchButton
                active={activeDurationIndex === index}
                key={index}
                onClick={() => setActiveDurationIndex(index)}
              >
                {planDuration.switcherText}
              </SwitchButton>
            ))}
          </PlanDurationSwitcher>
        </HeaderContainer>
        <PlansContainer>
          {plans.map((plan, index) => (
            <Plan key={index} featured={plan.featured}>
              {!plan.featured && <div className="planHighlight" />}
              <PlanHeader>
                <span className="name">{plan.name}</span>
                <span className="price">{plan.price[activeDurationIndex]}</span>
                <span className="duration">{planDurations[activeDurationIndex].text}</span>
              </PlanHeader>
              <PlanFeatures>
                <span className="feature mainFeature">{plan.mainFeature}</span>
                {plan.features.map((feature, index) => (
                  <span key={index} className="feature">
                    <FeatureIcon>{feature ? <CheckboxIcon /> : <CloseIcon />}</FeatureIcon>
                    {typeof feature === 'string' ? feature : ''}
                  </span>
                ))}
              </PlanFeatures>
              <PlanAction>
                <BuyNowButton
                  onClick={() => {
                    onChoose(plan.id, planDurations[activeDurationIndex].id);
                    onClose();
                  }}
                  disabled={plan.inDevelopment}
                >
                  {plan.inDevelopment ? 'Em breve' : 'Assine Agora'}
                </BuyNowButton>
              </PlanAction>
            </Plan>
          ))}
        </PlansContainer>
      </StyledDialogContent>
    </StyledDialogOverlay>
  );
}
