export const plans = [
  {
    id: 'busca',
    name: 'Busca',
    price: ['R$99,90', 'R$549,00', 'R$990,00'],
    duration: 'mensal',
    mainFeature: 'Para Pequenas Empresas',
    features: [
      'Pesquisa de Editais',
      // 'Pergunte ao Edital (Licite I.A.)',
      'Licitações favoritas',
      // 'Gestão de Logins e Senhas de portais',
      false,
      false,
      // false,
      // false,
      false,
      // false,
      // false,
      // false,
      // false,
      false,
      false,
      false,
      false,
      false,
    ],
  },
  {
    id: 'premium',
    name: 'Premium',
    price: ['R$149,00', 'R$790,00', 'R$1490,00'],
    duration: 'mensal',
    mainFeature: 'Para Empresas em Crescimento',
    features: [
      'Pesquisa de Editais',
      // 'Pergunte ao Edital (Licite I.A.)',
      'Licitações favoritas',
      // 'Gestão de Logins e Senhas de portais',
      'Aviso Diário de Licitação',
      'Pesquisa Automática de Licitação',
      // 'Gerador Automático de Declarações e Propostas',
      // 'Relatório de Participações',
      'Suporte por WhatsApp',
      false,
      false,
      false,
      false,
      false,
    ],
    featured: true,
  },
  {
    id: 'multi_empresa',
    name: 'Multi-Empresa',
    price: ['R$250,00', 'R$1390,00', 'R$2490,00'],
    duration: 'mensal',
    mainFeature: 'Para Grandes Empresas',
    features: [
      'Pesquisa de Editais',
      // 'Pergunte ao Edital (Licite I.A.)',
      'Licitações favoritas',
      // 'Gestão de Logins e Senhas de portais',
      'Aviso Diário de Licitação',
      'Pesquisa Automática de Licitação',
      // 'Gerador Automático de Declarações e Propostas',
      // 'Relatório de Participações',
      'Suporte por WhatsApp',
      'Gerenciamento de 5 ou 10 CNPJs',
      'Painel Multi-Empresa',
      'Controle de usuários',
      'Portal do cliente do Consultor',
      'CNPJ extra',
    ],
    inDevelopment: true,
  },
];
export const planDurations = [
  {
    id: 'mensal',
    switcherText: 'Mensal',
    text: 'Mensal',
  },
  {
    id: 'semestral',
    switcherText: 'Semestral',
    text: 'Semestral',
  },
  {
    id: 'anual',
    switcherText: 'Anual',
    text: 'Anual',
  },
];
