import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Landing from 'pages/Landing.js';
import LoginPage from 'pages/Login.js';
import NotFoundPage from 'pages/NotFound.js';
import PaymentPage from 'pages/Payment.js';
import PaymentConfirmation from 'pages/PaymentConfirmation.js';
import PrivacyPolicyPage from 'pages/PrivacyPolicy.js';
import SignupPage from 'pages/Signup.js';
import TermsOfServicePage from 'pages/TermsOfService.js';
import React, { useEffect } from 'react';
import useAuthStore from 'stores/authStore';
import GlobalStyles from 'styles/GlobalStyles';

import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

export default function App() {
  const initializeAuth = useAuthStore((state) => state.initializeAuth);

  useEffect(() => {
    initializeAuth();
  }, [initializeAuth]);

  return (
    <>
      <GlobalStyles />
      <Elements stripe={stripePromise}>
        <Router>
          <Routes>
            <Route path="/" element={<Landing />} />
            <Route path="/pagar" element={<PaymentPage />} />
            <Route path="/terms-of-service" element={<TermsOfServicePage />} />
            <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
            <Route path="/assine" element={<SignupPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/pagamento-confirmado" element={<PaymentConfirmation />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </Router>
      </Elements>
    </>
  );
}
