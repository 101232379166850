import axios from 'axios';
import { auth } from '../config/firebase';

const API_URL = process.env.REACT_APP_API_URL;

const api = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});
async function getUserToken() {
  const user = auth.currentUser;
  if (!user) {
    return null;
  }
  const token = await user.getIdToken();
  if (!token || token === '') {
    return null;
  }
  return token;
}
export const loadUserData = async () => {
  const token = await getUserToken();
  if (!token) {
    return null;
  }
  const response = await api.get('/api/auth/user-data', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const createPaymentIntent = async (planId, durationId) => {
  const token = await getUserToken();
  if (!token) {
    return null;
  }
  const response = await api.post(
    '/api/payments/create-payment-intent',
    { planId, durationId },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
  return response.data;
};

export const createSubscription = async (planId, durationId, userId, paymentIntentId) => {
  const token = await getUserToken();
  if (!token) {
    return null;
  }
  try {
    const response = await api.post(
      '/api/payments/create-subscription',
      { planId, durationId, userId, paymentIntentId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    console.log('API response:', response.data); // Add this line for debugging
    return response.data;
  } catch (error) {
    console.error('Error in createSubscription:', error);
    throw error;
  }
};
export const getToken = async () => {
  const token = await getUserToken();
  if (!token) {
    return null;
  }
  const response = await api.post(
    '/api/auth/get-token',
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
  return response.data;
};
